<template>
  <div class="flex flex-col h-full flex-grow overflow-hidden">
    <div :class="{'items-center justify-center' : !anyChartResult(data)}" class="flex w-full h-full">
      <app-highchart v-if="anyChartResult(data)" :categories="categories" :colors="colors" customClass="pr-4 pb-6" @toggle="toggleSeries" @highlight="highlightSeries" @mouseover="onMouseover" @mouseleave="onMouseleave" @changeActiveCategories="changeActiveCategories">
        <div v-if="!loading" class="flex w-full h-full">
          <highcharts v-if="averageChart" :ref="averageChart.ref" class="w-1/5 border-r-2 border-dotted border-secondary-100" :options="averageChart.options"></highcharts>
          <highcharts v-if="generalChart" :ref="generalChart.ref" class="w-full" :options="generalChart.options"></highcharts>
        </div>
      </app-highchart>
      <template v-else-if="touch">
        <base-not-found />
      </template>
    </div>
    <others-modal v-if="othersModalData"
      :isOpen="isOpenOthersModal"
      :title="title"
      :categories="totalCategories"
      :colors="totalColors"
      :data="othersModalData"
      @close="closeOthersModal"
    ></others-modal>
  </div>
</template>

<script>
import AppHighchart from '@/components/charts/AppHighchart'
import OthersModal from '@/components/modalViews/OthersModal'
import moment from 'moment'
import { getBrandShare } from '@/services/brand-share/getBrandShare'
import { transformBrandShare } from '@/utils/transformDataToHighcharts'
import { getColors } from '@/utils/colors'
import highchartMixin from '@/mixins/highchartMixin'
import csvMixin from '@/mixins/csvMixin'
import { LIMIT, OTHER_COLOR } from '@/config/general'
import { deepClone } from '@/utils/general'

export default {
  name: 'brandshare',
  components: {
    AppHighchart,
    OthersModal
  },
  mixins: [highchartMixin, csvMixin],
  data: function () {
    return {
      hasOthers: false,
      isOpenOthersModal: false,
      touch: false,
      data: [],
      categories: [],
      activeCategories: [],
      colors: [],
      totalData: [],
      totalColors: [],
      totalCategories: [],
      csvLinks: [
        { ref: 'general', title: this.$t('csv.generalBrandShare'), subtitle: this.$t('csv.file') },
        { ref: 'average', title: this.$t('csv.averageBrandShare'), subtitle: this.$t('csv.file') }
      ],
      othersModalData: null
    }
  },
  computed: {
    averageChart: function () {
      if (this.data.length) {
        return this.data[0]
      }
      return null
    },
    generalChart: function () {
      if (this.data.length >= 2) {
        return this.data[1]
      }
      return null
    },
    filterChangeCounter: function () {
      return this.$store.getters['filters/changeCounter']
    },
    selectedFilter1: function () {
      return this.$store.getters['filters/selectedDimensions'].filter1
    },
    selectedFilter2: function () {
      return this.$store.getters['filters/selectedDimensions'].filter2
    },
    selectedFilter3: function () {
      return this.$store.getters['filters/selectedDimensions'].filter3
    },
    selectedFilter4: function () {
      return this.$store.getters['filters/selectedDimensions'].filter4
    },
    selectedFilter5: function () {
      return this.$store.getters['filters/selectedDimensions'].filter5
    },
    groupId: function () {
      return this.$store.getters['filters/groupId']
    },
    categoryId: function () {
      return this.$store.getters['filters/categoryId']
    },
    siteId: function () {
      return this.$store.getters['filters/siteId']
    },
    startDate: function () {
      return this.$store.getters['filters/startDate']
    },
    endDate: function () {
      return this.$store.getters['filters/endDate']
    },
    minPrice: function () {
      return this.$store.getters['filters/minPrice']
    },
    maxPrice: function () {
      return this.$store.getters['filters/maxPrice']
    },
    currency: function () {
      return this.$store.getters['filters/currency']
    },
    brand: function () {
      return this.$store.getters['auth/user']?.nombreMarca
    },
    color: function () {
      return this.$store.getters['auth/user']?.color
    },
    csvChangeCounter: function () {
      return this.$store.getters['app/csvCounter']
    },
    csvRef: function () {
      return this.$store.getters['app/csvOptions'].ref
    },
    selectedHealthIndexFilter: function () {
      return this.$store.getters['filters/healthIndexFilter'].selected
    },
    actualCategories: function () {
      return this.activeCategories
    },
    title: function () {
      return this.$t('app.brandShare') + ' - ' + this.$t('app.others')
    },
    loading: function () {
      return this.$store.getters['app/loading']
    }
  },
  created: function () {
    this.$store.commit('app/setCsvLinks', this.csvLinks)
    this.downloadingData()
  },
  methods: {
    downloadingData: async function () {
      try {
        this.$store.commit('app/setLoading', true)
        await this.fetchBrandShare()
      } catch (error) {
        this.doFeedback()
      } finally {
        this.touch = true
        this.$store.commit('app/setLoading', false)
      }
    },
    fetchBrandShare: async function () {
      const groupId = this.groupId
      const categoryId = this.categoryId
      const siteId = this.siteId
      const startDate = moment(this.startDate).format('DD-MM-YYYY')
      const endDate = moment(this.endDate).format('DD-MM-YYYY')
      const body = {
        precioMin: this.minPrice,
        precioMax: this.maxPrice
      }

      if (this.selectedHealthIndexFilter && this.selectedHealthIndexFilter.length === 1) {
        body.healthIndex = this.selectedHealthIndexFilter[0].toString()
      }
      if (this.selectedFilter1 && this.selectedFilter1.length) {
        body.filtro1 = this.selectedFilter1
      }
      if (this.selectedFilter2 && this.selectedFilter2.length) {
        body.filtro2 = this.selectedFilter2
      }
      if (this.selectedFilter3 && this.selectedFilter3.length) {
        body.filtro3 = this.selectedFilter3
      }
      if (this.selectedFilter4 && this.selectedFilter4.length) {
        body.filtro4 = this.selectedFilter4
      }
      if (this.selectedFilter5 && this.selectedFilter5.length) {
        body.filtro5 = this.selectedFilter5
      }

      const data = await getBrandShare(groupId, categoryId, siteId, startDate, endDate, body)

      this.$store.commit('filters/setHealthIndexOK', data.healthIndexOK)
      this.$store.commit('filters/setHealthIndexKO', data.healthIndexKO)
      this.$store.commit('filters/setFilters', {
        filter1: data.filtro1,
        filter2: data.filtro2,
        filter3: data.filtro3,
        filter4: data.filtro4,
        filter5: data.filtro5
      })

      let colors = []
      let categories = []
      let brandshareData = []
      let totalColors = []
      let totalCategories = []
      let brandshareTotalData = []

      if (data.lista.length) {
        const transformTotalData = transformBrandShare(data.lista)
        brandshareTotalData = transformTotalData.charts

        const transformData = transformBrandShare(data.lista, LIMIT)
        brandshareData = transformData.charts
        categories = transformData.categories
        totalCategories = transformData.totalCategories

        const othersLabel = this.$t('app.others')
        if (categories.includes(othersLabel)) {
          this.hasOthers = true
          categories = categories.filter(category => category !== othersLabel)
          categories.push(othersLabel)
        } else {
          this.hasOthers = false
        }

        totalColors = this.getColors(totalCategories)
        colors = this.getCustomColors(categories, totalCategories, totalColors)

        brandshareData = this.setChartsColors(brandshareData, categories, colors)
        brandshareData = this.setSeriesEvents(brandshareData)
      }

      this.colors = colors
      this.categories = categories
      this.totalColors = totalColors
      this.totalCategories = totalCategories
      this.data = brandshareData
      this.totalData = brandshareTotalData

      this.$store.commit('filters/setResultsCounter', categories.length)
    },
    getColors: function (categories) {
      const length = categories.length
      const colors = getColors(length)
      const othersLabel = this.$t('app.others')
      if (categories.includes(othersLabel)) {
        const position = categories.findIndex(brand => brand === othersLabel)
        colors[position] = OTHER_COLOR
      }

      const color = this.color
      const brand = this.brand
      if (categories.includes(brand)) {
        const position = categories.findIndex(item => item === brand)
        colors[position] = color
      }

      return colors
    },
    getCustomColors: function (categories, totalCategories, totalColors) {
      return categories.map(category => {
        const index = totalCategories.findIndex(c => c === category)
        return totalColors[index]
      })
    },
    setChartsColors: function (charts, categories, colors) {
      for (const chart of charts) {
        const seriesBrands = chart.series.map(serie => serie.name)
        const c = this.getColorsByCategories(seriesBrands, categories, colors)
        chart.options.colors = c
      }

      return charts
    },
    getColorsByCategories: function (seriesBrands, categories, colors) {
      return seriesBrands.map(category => {
        const position = categories.findIndex(b => b === category)
        return colors[position]
      })
    },
    setSeriesEvents: function (data) {
      for (const chart of data) {
        chart.options.plotOptions.series.events = {
          mouseOut: this.serieMouseOut.bind(this),
          mouseOver: this.serieMouseOver.bind(this)
        }
      }
      if (this.hasOthers) {
        const that = this
        data[1].options.plotOptions.series.events = {
          click: function (e) {
            if (this.name === that.$t('app.others')) {
              const x = e.point.category
              that.openOthersModal(x)
            }
          }
        }

        data[1].options.chart.events = {
          load: function () {
            const serie = this.series.find(s => s.name === that.$t('app.others'))
            if (serie) {
              serie.data.forEach(point => {
                if (point.graphic) {
                  point.graphic.attr({
                    cursor: 'pointer'
                  })
                }
              })
            }
          }
        }
      }

      return data
    },
    changeActiveCategories: function (categories) {
      this.activeCategories = categories
    },
    openOthersModal: function (category) {
      const series = deepClone(this.generalChart.series)
      const totalSeries = deepClone(this.totalData[1].series)
      const options = deepClone(this.generalChart.options)
      const othersOptions = deepClone(this.generalChart.options)
      const categories = options.xAxis.categories
      const categoryIndex = categories.findIndex(c => c === category)
      const othersCategories = othersOptions.xAxis.categories
      const othersCategoryIndex = othersCategories.findIndex(c => c === category)
      options.xAxis.categories = [category]
      othersOptions.xAxis.categories = [category]

      const serie = series.map(s => {
        s.data = [s.data[categoryIndex]]
        return s
      })

      const otherSerie = this.getOthersSerie(totalSeries, othersCategoryIndex, serie.filter(s => s.data[0]).map(s => s.name))
      const auxCategories = otherSerie.map(s => s.name)
      const colors = this.getColorsByCategories(auxCategories, this.totalCategories, this.totalColors)
      othersOptions.colors = colors
      const max = otherSerie.map(s => s.data[0] ? s.data[0] : 0).reduce((a, b) => a + b, 0)
      othersOptions.yAxis.max = max
      othersOptions.yAxis.tickPositioner = function () {
        const positions = this.tickPositions
        if (positions.length) {
          positions[positions.length - 1] = max
        }
        return positions
      }

      options.series = serie
      othersOptions.series = otherSerie

      this.othersModalData = [
        {
          ref: 'general',
          options: options,
          series: serie
        },
        {
          ref: 'others',
          options: othersOptions,
          series: otherSerie
        }
      ]
      this.isOpenOthersModal = true
    },
    getOthersSerie: function (series, categoryIndex, categories) {
      const otherSerie = series.filter(s => !categories.includes(s.name)).map(s => {
        s.data = [s.data[categoryIndex]]
        return s
      })

      return otherSerie.sort((a, b) => b.data[0] - a.data[0])
    },
    closeOthersModal: function () {
      this.isOpenOthersModal = false
      this.othersModalData = null
    }
  },
  watch: {
    filterChangeCounter: function () {
      this.downloadingData()
    },
    csvChangeCounter: function () {
      const ref = this.csvRef

      if (ref === 'general') {
        const data = this.getCSVFromColumnChart(this.generalChart.options)
        this.downloadCsv(data, 'brandshare')
      } else if (ref === 'average') {
        const data = this.getCSVFromColumnChart(this.averageChart.options, 'percent', null)
        this.downloadCsv(data, 'brandshare-average')
      }
    }
  }
}
</script>
