<template>
  <div :class="[isOpen ? 'translate-y-0 shadow-fullview':'translate-y-full']" class="fixed bg-white w-full h-full top-0 left-0 transition transform translate-y-0 z-50 duration-300">
    <div class="flex flex-col h-full">
      <header class="flex-shrink-0 flex flex-col items-center justify-center py-2 text-sm relative bg-secondary text-white">
        <h3 class="font-bold leading-tight">{{ title }}</h3>
        <h4 class="opacity-80 font-semibold">
          {{ subtitle }}
        </h4>
        <base-button-icon
          @click="closeModal"
          name="cross"
          size="xs"
          iconSize="xs"
          mode="soft"
          custom="absolute right-2 top-2"
        />
      </header>
      <div class="flex flex-grow overflow-hidden">
        <app-highchart :categories="localCategories" :colors="localColors" customClass="pr-4 pb-6" @toggle="toggleSeries" @highlight="highlightSeries" @mouseover="onMouseover" @mouseleave="onMouseleave" @changeActiveCategories="changeActiveCategories">
          <div class="flex w-full h-full">
            <highcharts v-if="generalChart" :ref="generalChart.ref" :updateArgs="[true, false]" class="w-1/5 border-r-2 border-dotted border-secondary-100" :options="generalChart.options"></highcharts>
            <highcharts v-if="othersChart" :ref="othersChart.ref" :updateArgs="[true, false]" class="w-full" :options="othersChart.options"></highcharts>
          </div>
        </app-highchart>
      </div>
    </div>
  </div>
</template>

<script>
import AppHighchart from '@/components/charts/AppHighchart'
import highchartMixin from '@/mixins/highchartMixin'
import moment from 'moment'
import { deepClone } from '@/utils/general'
import { GENERAL_COLOR, OTHER_COLOR, ACCENT_COLOR } from '@/config/general'

export default {
  name: 'others-modal',
  components: {
    AppHighchart
  },
  mixins: [highchartMixin],
  props: {
    categories: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    hiddenSite: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      activeCategories: [],
      localCategories: this.categories,
      localColors: this.colors
    }
  },
  computed: {
    generalChart: function () {
      const othersLabel = this.$t('app.others')
      const generalLabel = this.$t('app.general')
      const data = deepClone(this.data[0])
      const othersSerie = data.options.series.find(serie => serie.name === othersLabel)
      othersSerie.borderColor = ACCENT_COLOR
      othersSerie.borderWidth = '2'
      const series = [
        {
          name: generalLabel,
          data: [100 - othersSerie.data[0]]
        },
        othersSerie
      ]

      data.options.series = series
      if (data.options.chart && data.options.chart.events) {
        data.options.chart.events.load = undefined
      }

      const colors = [GENERAL_COLOR, OTHER_COLOR]
      data.options.colors = colors
      data.options.plotOptions.series.clip = false

      return data
    },
    othersChart: function () {
      return this.data[1]
    },
    date: function () {
      if (this.generalChart) {
        return this.generalChart.options.xAxis.categories[0]
      }
      return null
    },
    locale: function () {
      return this.$store.getters['auth/locale']
    },
    site: function () {
      return this.$store.getters['filters/site']
    },
    category: function () {
      const locale = this.locale
      return this.$store.getters['filters/category'](locale)
    },
    subtitle: function () {
      let text = ''

      if (!this.hiddenSite) {
        text += this.site + ' - '
      }

      text += this.category + ' - '
      text += this.transformDate(this.date)

      return text
    }
  },
  created: function () {
    this.updateData()
  },
  methods: {
    transformDate: function (value, format = 'D MMM, YY') {
      if (value) {
        const date = moment(value, 'YYYY-MM-DD')

        return date.isValid() ? date.format(format) : value
      }

      return ''
    },
    closeModal: function () {
      this.$emit('close')
    },
    changeActiveCategories: function (categories) {
      this.activeCategories = categories
    },
    updateData: function () {
      const sorteable = []
      for (const chart of [this.othersChart]) {
        const series = chart.options.series
        for (const serie of series) {
          if (serie.data[0]) {
            if (serie.name !== this.$t('app.others')) {
              sorteable.push({
                name: serie.name,
                value: serie.data[0]
              })
            } else {
              sorteable.push({
                name: serie.name,
                value: -10
              })
            }
          }
        }
      }

      sorteable.sort((a, b) => b.value - a.value)
      const categories = sorteable.map(item => item.name)
      const colors = categories.map(category => {
        const position = this.categories.findIndex(c => c === category)
        return this.colors[position]
      })

      this.localCategories = categories
      this.localColors = colors
    }
  },
  watch: {
    data: function () {
      this.updateData()
    }
  }
}
</script>

<style lang="scss" scoped>
.vc-container {
  border: none !important;
}
.max-height-calendar {
  max-height: 400px;
}
</style>
