<template>
  <div v-if="!loading" class="flex w-full h-full overflow-hidden">
    <div :class="customClass" class="w-full h-full">
      <slot name="title" v-if="hasSlotTitle">
        <h3 class="font-bold text-2xl leading-tight py-2">{{ title }}</h3>
      </slot>
      <slot>
        <highcharts v-if="chartOptions" ref="chart" class="chart" :class="customChartClass" :updateArgs="[true, false]" :options="chartOptions"></highcharts>
      </slot>
    </div>
    <slot name="legend">
      <div v-if="!disableLegend" class="w-65 flex-shrink-0 overflow-y-auto space-y-2 p-2 pb-4 relative">
        <div class="space-y-2 p-2 pb-4">
          <legend-label
            v-for="(category, index) in categories"
            :key="index"
            :category="category"
            :color="colors[index]"
            @toggle="toggleSeries"
            @highlight="highlightSeries"
            @mouseover="onMouseover"
            @mouseleave="onMouseleave"
          />
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import LegendLabel from '@/components/LegendLabel'

export default {
  name: 'AppHighchart',
  components: {
    LegendLabel
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    chart: {
      type: Object,
      required: false
    },
    categories: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    disableLegend: {
      type: Boolean,
      default: false
    },
    tight: {
      type: Boolean,
      default: false
    },
    width: {
      default: '100%'
    },
    height: {
      default: 'auto'
    },
    customClass: {
      type: String,
      default: ''
    },
    customChartClass: {
      type: String,
      default: ''
    },
    enableEvents: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      chartOptions: this.chart ? this.chart.options : null,
      legendHover: null,
      legendDisable: [],
      checkedLegend: [],
      last_known_scroll_position: 0,
      ticking: false,
      reference: 0
    }
  },
  computed: {
    hasSlotTitle: function () {
      return this.$scopedSlots.title
    },
    localCategories: function () {
      if (this.chart) {
        return this.chartOptions.series.map(serie => serie.name)
      }
      return this.categories
    }
  },
  created: function () {
    this.$emit('changeActiveCategories', this.localCategories)
    if (this.chartOptions && this.enableEvents) {
      this.chartOptions.plotOptions.series.events.mouseOut = this.serieMouseOut.bind(this)
      this.chartOptions.plotOptions.series.events.mouseOver = this.serieMouseOver.bind(this)
    }
  },
  methods: {
    isBrandInSerie: function (brandName, serieRef) {
      const chart = this.chart
      if (chart) {
        const serie = chart.series.find(serie => serie.name === brandName)

        return !!serie
      }

      return false
    },
    isDisableLegend: function (name) {
      return this.legendDisable.includes(name)
    },
    getColor: function (index) {
      if (index < this.options.colors.length) {
        return this.options.colors[index]
      }

      return ''
    },
    setLegendHover: function (value) {
      this.legendHover = value
    },
    resetLegendHover: function () {
      this.legendHover = null
    },
    toggleSeries: function (name) {
      if (this.chart) {
        if (this.legendDisable.includes(name)) {
          this.legendDisable = this.legendDisable.filter(item => item !== name)
        } else {
          this.legendDisable.push(name)
        }

        const chart = this.$refs.chart?.chart
        const index = this.chart.series.findIndex(serie => serie.name === name)
        if (chart && index >= 0) {
          const series = chart.series
          if (series[index].visible) {
            series[index].hide()
          } else {
            series[index].show()
          }
        }
      } else {
        this.$emit('toggle', name)
      }
    },
    highlightSeries: function (value) {
      if (this.chart) {
        if (this.checkedLegend.includes(value)) {
          this.checkedLegend = this.checkedLegend.filter(item => item !== value)
        } else {
          this.checkedLegend.push(value)
        }
      } else {
        this.$emit('highlight', value)
      }
    },
    onMouseover: function (name) {
      if (this.chart) {
        this.legendHover = name
      } else {
        this.$emit('mouseover', name)
      }
    },
    onMouseleave: function (name) {
      if (this.chart) {
        this.legendHover = null
      } else {
        this.$emit('mouseleave', name)
      }
    },
    addInactiveSeriesAll: function () {
      this.setStateSeriesAll('inactive')
    },
    removeInactiveSeriesAll: function () {
      this.setStateSeriesAll()
    },
    removeInactiveSerie: function (name) {
      this.setStateOfSerie(name, 'hover')
    },
    setStateSeriesAll: function (state = '') {
      const chartEl = this.$refs.chart
      if (chartEl && chartEl.chart) {
        const series = chartEl.chart.series
        if (series && series.length) {
          for (const serie of series) {
            serie.setState(state)
          }
        }
      }
    },
    setStateOfSerie: function (name, state = '') {
      const chartEl = this.$refs.chart
      if (chartEl && chartEl.chart) {
        const chart = chartEl.chart
        const series = chart.series
        if (series) {
          const index = series.findIndex(serie => serie.name === name)
          if (index >= 0 && index < series.length) {
            series[index].setState(state)
          }
        }
      }
    },
    setCheckedLegend: function () {
      if (this.checkedLegend.length) {
        this.addInactiveSeriesAll()
        for (const value of this.checkedLegend) {
          this.removeInactiveSerie(value)
        }
      } else {
        this.removeInactiveSeriesAll()
      }
    },
    serieMouseOut: function () {
      setTimeout(() => {
        this.legendHover = null
      }, 100)
    },
    serieMouseOver: function (event) {
      const name = event.target.name
      setTimeout(() => {
        this.legendHover = name
      }, 100)
    }
  },
  watch: {
    legendHover: function (value) {
      if (value !== null) {
        this.addInactiveSeriesAll()
        this.removeInactiveSerie(value)

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      } else {
        this.setCheckedLegend()

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      }
    },
    legendDisable: function () {
      const legendDisable = this.legendDisable
      const categories = this.localCategories.filter(category => !legendDisable.includes(category))
      this.$emit('changeActiveCategories', categories)
    },
    chart: function () {
      this.chartOptions = this.chart ? this.chart.options : null
    },
    localCategories: function () {
      this.$emit('changeActiveCategories', this.localCategories)
    }
  }
}
</script>
<style lang="scss" scoped></style>
