export default {
  data: function () {
    return {
      legendHover: null,
      legendDisable: [],
      checkedLegend: []
    }
  },
  computed: {
    references: function () {
      return this.data.map(item => item.ref)
    }
  },
  methods: {
    toggleSeries: function (name) {
      if (this.legendDisable.includes(name)) {
        this.legendDisable = this.legendDisable.filter(item => item !== name)
      } else {
        this.legendDisable.push(name)
      }

      for (const ref of this.references) {
        const index = this.getChartIndexByName(name, ref)
        if (index >= 0) {
          const chart = this.$refs[ref].chart
          if (chart) {
            const series = chart.series
            if (series[index].visible) {
              series[index].hide()
            } else {
              series[index].show()
            }
          }
        }
      }
    },
    highlightSeries: function (value) {
      if (this.checkedLegend.includes(value)) {
        this.checkedLegend = this.checkedLegend.filter(item => item !== value)
      } else {
        this.checkedLegend.push(value)
      }
    },
    onMouseover: function (name) {
      this.legendHover = name
    },
    onMouseleave: function () {
      this.legendHover = null
    },
    getChartIndexByName: function (brandName, serieRef) {
      let index = -1
      const object = this.data.find(item => item.ref === serieRef)

      if (object) {
        index = object.series.findIndex(serie => serie.name === brandName)
      }

      return index
    },
    addInactiveSeriesAll: function () {
      this.setStateSeriesAll('inactive')
    },
    removeInactiveSeriesAll: function () {
      this.setStateSeriesAll()
    },
    removeInactiveSerie: function (name) {
      this.setStateOfSerie(name, 'hover')
    },
    setStateSeriesAll: function (state = '') {
      for (const reference of this.references) {
        const chartEl = this.$refs[reference]
        if (chartEl && chartEl.chart) {
          const series = chartEl.chart.series
          if (series && series.length) {
            for (const serie of series) {
              serie.setState(state)
            }
          }
        }
      }
    },
    setStateOfSerie: function (name, state = '') {
      for (const ref of this.references) {
        const index = this.getChartIndexByName(name, ref)
        if (index >= 0) {
          const chartEl = this.$refs[ref]
          if (chartEl && chartEl.chart) {
            const chart = chartEl.chart
            const series = chart.series
            if (series) {
              if (index < series.length) {
                series[index].setState(state)
              }
            }
          }
        }
      }
    },
    setCheckedLegend: function () {
      if (this.checkedLegend.length) {
        this.addInactiveSeriesAll()
        for (const value of this.checkedLegend) {
          this.removeInactiveSerie(value)
        }
      } else {
        this.removeInactiveSeriesAll()
      }
    },
    serieMouseOut: function () {
      setTimeout(() => {
        this.legendHover = null
      }, 100)
    },
    serieMouseOver: function (event) {
      const name = event.target.name
      setTimeout(() => {
        this.legendHover = name
      }, 100)
    }
  },
  watch: {
    legendHover: function (value) {
      if (value !== null) {
        this.addInactiveSeriesAll()
        this.removeInactiveSerie(value)

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      } else {
        this.setCheckedLegend()

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      }
    },
    checkedLegend: function () {
      this.setCheckedLegend()
    },
    legendDisable: function () {
      const legendDisable = this.legendDisable
      const categories = this.categories.filter(category => !legendDisable.includes(category))
      this.activeCategories = categories
    }
  }
}
