const LIMIT = 5
const OTHER_COLOR = '#555B69'
const GENERAL_COLOR = '#9A9DA6'
const ACCENT_COLOR = '#086DFF'
const LANGUAGES = [
  {
    code: 'es',
    name: 'Español'
  },
  {
    code: 'en',
    name: 'English'
  }
]

export {
  LIMIT,
  OTHER_COLOR,
  GENERAL_COLOR,
  ACCENT_COLOR,
  LANGUAGES
}
