
import { getDecimalFormat, getPercentFormat } from '@/i18n/numbers'
import moment from 'moment'

const { parse } = require('json2csv')

export default {
  methods: {
    exportChartToCSV: function (ctx, w, filename, options = {}) {

    },
    exportToCSV ({ ctx, w, series, filename = null, columnDelimiter = ',', lineDelimiter = '\n', options }) {

    },
    triggerDownload: function (href, filename = 'file', ext) {
      const downloadLink = document.createElement('a')
      downloadLink.href = href
      downloadLink.download = filename + ext
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },

    downloadCsv: function (data, filename = 'data', ext = '.csv') {
      const href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(data)
      const downloadLink = document.createElement('a')
      downloadLink.href = href
      downloadLink.download = filename + ext
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },
    getCSVFromColumnChart: function (data, xType = 'percent', yType = 'date', ignoreEmpty = true) {
      const csvOptions = {
        delimiter: ';',
        fields: ['category', ...this.actualCategories]
      }
      const csvData = []
      const categories = data.xAxis.categories
      const series = data.series
      for (let index = 0; index < categories.length; index++) {
        let category = categories[index]
        if (yType === 'date') {
          category = moment(category, 'DD-MM-YYYY').format('D MMM, YY')
        }
        const row = {
          category: category
        }

        for (const serie of series) {
          let value = serie.data[index]
          if (!ignoreEmpty || value) {
            if (xType === 'percent') {
              value = getPercentFormat(value)
            } else {
              value = getDecimalFormat(value)
            }
            row[serie.name] = value
          }
        }

        csvData.push(row)
      }

      return parse(csvData, csvOptions)
    },
    getCSVFromTreemapChart: function (data, percent = true) {
      const treemapData = data.series[0].data
      const items = Array.from(new Set(treemapData.filter(item => item.parent).map(item => item.name)))
      const csvOptions = {
        delimiter: ';',
        fields: ['category', 'general', ...items]
      }

      let total = 0
      const csvData = []
      const categories = [...this.actualCategories]
      for (const category of categories) {
        const itemData = treemapData.filter(i => i.parent === category)
        const general = itemData.map(i => i.value).reduce((a, b) => a + b, 0)
        total += general

        const item = {
          category,
          general,
          ...itemData.reduce((a, v) => ({ ...a, [v.name]: v.value }), {})
        }

        csvData.push(item)
      }

      if (percent) {
        for (const object of csvData) {
          for (const key in object) {
            if (key !== 'category') {
              object[key] = getPercentFormat((object[key] * 100) / total)
            }
          }
        }
      }

      return parse(csvData, csvOptions)
    }
  }
}
